import styled from "@emotion/styled";
import { arrayOf, oneOf, oneOfType, shape, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Heading from "../Heading";
import ImageComponent, { ImageDataType } from "../ImageComponent";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("xxl")};
  ${mq("3")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Divider = styled.div`
  display: none;
  position: relative;
  height: 136px;
  width: 1px;
  background-color: ${theme.color("dark.lighter")};
  ${mq("3")} {
    display: block;
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${theme.spacing("l")};
`;

const StyledHeading = styled(Heading)`
  text-transform: uppercase;
`;

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HonorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LogoGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing("l")};
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled(ImageComponent)`
  max-height: 136px;
  max-width: 136px;
  img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const BasicImage = styled.img`
  max-height: 136px;
  max-width: 136px;
`;

function Partners({ partnersTitle, partners, honorsTitle, honors, ...props }) {
  return (
    <Wrapper {...props}>
      <PartnersContainer>
        <HeadingWrapper>
          <StyledHeading size={5}>{partnersTitle}</StyledHeading>
        </HeadingWrapper>
        <LogoGrid>
          {partners.map(({ logo, id }) => (
            <Item key={`awardsLogos-${id}`}>
              {logo.data !== null ? (
                <StyledImage data={logo.data} />
              ) : (
                <BasicImage src={logo.url} alt={logo.alt} title={logo.title} />
              )}
            </Item>
          ))}
        </LogoGrid>
      </PartnersContainer>
      <Divider />
      <HonorsContainer>
        <HeadingWrapper>
          <StyledHeading size={5}>{honorsTitle}</StyledHeading>
        </HeadingWrapper>
        <LogoGrid>
          {honors.map(({ logo, id }) => (
            <Item key={`awardsLogos-${id}`}>
              {logo.data !== null ? (
                <StyledImage data={logo.data} />
              ) : (
                <BasicImage src={logo.url} alt={logo.alt} title={logo.title} />
              )}
            </Item>
          ))}
        </LogoGrid>
      </HonorsContainer>
    </Wrapper>
  );
}

Partners.propTypes = {
  partnersTitle: string,
  partners: arrayOf(
    shape({
      id: string,
      logo: shape({
        title: string,
        url: string,
        alt: string,
        data: oneOfType([oneOf([null]), shape(ImageDataType)]),
      }),
    })
  ),
  honorsTitle: string,
  honors: arrayOf(
    shape({
      id: string,
      logo: shape({
        title: string,
        url: string,
        alt: string,
        data: oneOfType([oneOf([null]), shape(ImageDataType)]),
      }),
    })
  ),
};

Partners.defaultProps = {};

export default Partners;
